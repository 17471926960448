import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import RegisterForm from './register/RegisterForm'
import SelectUserGroup from './elements/SelectUserGroup'
import SelectVersion from './elements/SelectVersion'
import StatusPanel from './graphic/StatusPanel'
import DropDownMenu from './graphic/DropDownMenu'
import RegistersList from './register/RegisterList'

import useQuotesStore from '../lib/stores/quotes'
import useDocumentStore from '../lib/stores/document'
import useVersionStore from '../lib/stores/version'
import useUserStore from '../lib/stores/user'
import useModalStore from '../lib/stores/modal'
import { upsertQuote as adminUpsertQuote } from '../lib/adminFunctions'
import checkTokenError from '../lib/checkTokenError'

import '../styles/layout/DocumentHeader.scss'

export default function DocumentHeader() {
	const { document, register, setRegister, clearStore, setUserGroupId, hasVersion } = useDocumentStore((state) => ({
		document: state.document,
		register: state.register,
		setRegister: state.setRegister,
		clearStore: state.clearStore,
		setUserGroupId: state.setUserGroupId,
		hasVersion: state.hasVersion,
		versionNumber: state.versionNumber,
	}))
	const { updateVersion } = useVersionStore((state) => ({
		versions: state.versions,
		selectedVersion: state.selectedVersion,
		updateVersion: state.updateVersion,
	}))
	const addModal = useModalStore((state) => state.addModal)
	const { uploadQuote, replaceQuote } = useQuotesStore((state) => ({
		uploadQuote: state.uploadQuote,
		replaceQuote: state.replaceQuote,
	}))
	const { user, getUserGroupId, getUserGroup } = useUserStore((state) => ({
		logout: state.logout,
		user: state.user,
		getUserGroupId: state.getUserGroupId,
		getUserGroup: state.getUserGroup,
	}))
	const navigate = useNavigate()

	const [userGroupDownload, setUserGroupDownload] = useState()
	const [userGroup, setUserGroup] = useState()

	useEffect(() => {
		// console.log(typeof getUserGroupId)
		if (typeof getUserGroupId === 'function' && document && document.userGroupId)
			getUserGroupId(document && document.userGroupId)
				.then((res) => setUserGroupDownload(res))
				.catch((e) => console.error(e))

		if (typeof getUserGroup === 'function') getUserGroup().then((res) => setUserGroup(res && res.data))
	}, [document, getUserGroup, getUserGroupId])

	let quoteId = ''

	if (document.identifier) {
		let userSlug = user.slug
		let id = document.identifier

		let userGroupId = userGroupDownload && userGroupDownload.id

		if (userGroupDownload instanceof Error) {
			userGroupId = userGroup && userGroup.id
		}

		// console.log(userGroupId, typeof userGroupId)

		id = id.replace(/^0+/, '')

		quoteId = `${userSlug}${userGroupId ? '-' + userGroupId : ''}-${id}${document.versionNumber ? '_' + document.versionNumber : ''}`
	}

	// console.log(document)

	// console.log(userGroup, userGroupDownload, user, document)
	// console.log(cloneDeep(document))

	return (
		<div className="document-header">
			<h2>ID Preventivo: {quoteId ? quoteId : 'nessuno'}</h2>
			{((user && user.authLevel >= 250) || (user && user.userGroup && user.userGroup.authLevel >= 250)) && (
				<nav className="document-header-menu">
					<div className="document-header-item">
						<div
							className="document-header-button"
							onClick={() => {
								const r = window.confirm(`Vuoi creare un nuovo preventivo? (perderai le modifiche non salvate)`)

								if (r) {
									clearStore()
									navigate('/')
									// uploadQuote()
								}
							}}
						>
							Nuovo
						</div>
					</div>

					{((user && user.authLevel >= 750) || (user && user.userGroup && user.userGroup.authLevel >= 250)) && (
						<>
							<div className="document-header-item">
								<div
									className="document-header-button"
									onClick={() => {
										const id = toast.loading('Salvando preventivo...')

										if (hasVersion) {
											updateVersion()
												.then((res) => {
													// console.log(res)

													toast.update(id, {
														render: 'Versione salvata!',
														type: 'success',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
												.catch((e) => {
													console.error(e)

													let message = checkTokenError(e)
													if (!message) message = 'Errore salvando versione'

													toast.update(id, {
														render: message,
														type: 'error',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
										} else if (user && user.authLevel >= 750 && document.userGroupId) {
											adminUpsertQuote()
												.then((res) => {
													// console.log(res)

													toast.update(id, {
														render: 'Preventivo salvato!',
														type: 'success',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
												.catch((e) => {
													console.error(e)

													let message = checkTokenError(e)
													if (!message) message = 'Errore sostituendo preventivo'

													toast.update(id, {
														render: message,
														type: 'error',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
										} else {
											uploadQuote()
												.then((res) => {
													// console.log(res)

													toast.update(id, {
														render: 'Preventivo salvato!',
														type: 'success',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
												.catch((e) => {
													console.error(e)

													toast.update(id, {
														render: 'Errore salvando preventivo',
														type: 'error',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
										}
									}}
								>
									{document.id && !hasVersion ? 'Duplica' : 'Salva'}
								</div>
							</div>
							{register && (
								<>
									<div className="document-header-item">
										<div
											className="document-header-button"
											onClick={() => {
												addModal({
													modal: <RegisterForm registerData={register} setRegisterData={setRegister} mode="editLocal" />,
													id: 'EditRegisterModal',
													background: true,
													replaceModal: true,
													customModal: true,
													disposableModal: true,
												})
											}}
										>
											Modifica Anagrafica
										</div>
									</div>
									<div className="document-header-item">
										<div
											className="document-header-button"
											onClick={() => {
												setRegister(undefined)
											}}
										>
											Rimuovi Anagrafica
										</div>
									</div>
								</>
							)}
						</>
					)}
					{user && user.authLevel >= 750 && (
						<div className="document-header-item">
							<DropDownMenu
								reverse={true}
								icon={<div className="document-header-button">Gruppo utente</div>}
								content={
									<div className="header-drop-down-container">
										<SelectUserGroup
											value={document.userGroupId}
											setValue={(option, index) => {
												setUserGroupId(option.value)
											}}
											toggleOwn={true}
										/>
									</div>
								}
							/>
						</div>
					)}
					{user && user.userType !== 2 && (
						<>
							<div className="document-header-item">
								<DropDownMenu
									reverse={true}
									icon={<div className="document-header-button">Scegli versione</div>}
									content={
										<div className="header-drop-down-container">
											<SelectVersion />
										</div>
									}
								/>
							</div>
							<div className="document-header-item">
								<DropDownMenu reverse={true} icon={<div className="document-header-button">Stati ordine</div>} content={<StatusPanel />} />
							</div>

							<div className="document-header-item">
								<div
									className="document-header-button"
									onClick={() => {
										addModal({ modal: <RegistersList />, id: 'RegistersListModal', background: true, replaceModal: false })
									}}
								>
									Clienti
								</div>
							</div>
						</>
					)}

					{document.id && !hasVersion && (
						<>
							<div className="document-header-item">
								<div
									className="document-header-button"
									onClick={() => {
										const id = toast.loading(`Sostituendo ${hasVersion ? 'versione' : 'preventivo'}`)

										if (hasVersion) {
											updateVersion()
												.then((res) => {
													// console.log(res)

													toast.update(id, {
														render: 'Versione sostituita!',
														type: 'success',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
												.catch((e) => {
													console.error(e)

													let message = checkTokenError(e)
													if (!message) message = 'Errore sostituendo versione'

													toast.update(id, {
														render: message,
														type: 'error',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
										} else if (user && user.authLevel >= 750 && document.userGroupId) {
											adminUpsertQuote(document.id)
												.then((res) => {
													// console.log(res)

													toast.update(id, {
														render: 'Preventivo sostituito!',
														type: 'success',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
												.catch((e) => {
													console.error(e)

													let message = checkTokenError(e)
													if (!message) message = 'Errore sostituendo preventivo'

													toast.update(id, {
														render: message,
														type: 'error',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
										} else {
											replaceQuote(document.id, document.quoteIdentifier ? false : true)
												.then((res) => {
													// console.log(res)

													toast.update(id, {
														render: 'Preventivo sostituito!',
														type: 'success',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
												.catch((e) => {
													console.error(e)

													let message = checkTokenError(e)
													if (!message) message = 'Errore sostituendo preventivo'

													toast.update(id, {
														render: message,
														type: 'error',
														autoClose: 5000,
														isLoading: false,
														closeOnClick: true,
														pauseOnHover: true,
													})
												})
										}
									}}
								>
									Salva
									{/* Salva Preventivo
												<br />#{document.id} */}
								</div>
							</div>
						</>
					)}
				</nav>
			)}
		</div>
	)
}
