import { useEffect, useState, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { round } from 'mathjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

import Pagination from './Pagination'
import Filters from './Filters'
import Loading from './Loading'

import useDocumentStore from '../../lib/stores/document'
import useQuotesStore from '../../lib/stores/quotes'

import '../../styles/components/List.scss'

export default function QuotesList() {
	const [searchParams] = useSearchParams()
	const [paramsLoaded, setParamsLoaded] = useState(false)
	const [selectedList, setSelectedList] = useState({})
	const { _hasHydrated, fetchQuotes, quotes, totalQuotes, deleteManyQuotes, parseQuote } = useQuotesStore((state) => ({
		_hasHydrated: state._hasHydrated,
		fetchQuotes: state.fetchQuotes,
		quotes: state.quotes,
		totalQuotes: state.totalQuotes,
		deleteManyQuotes: state.deleteManyQuotes,
		parseQuote: state.parseQuote,
	}))
	const { clearStore, replaceDocument } = useDocumentStore((state) => ({ clearStore: state.clearStore, replaceDocument: state.replaceDocument }))
	const navigate = useNavigate()
	const [loading, setLoading] = useState(true)

	// Pagination Info
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(50)

	// Filter Info
	const [searchString, setSearchString] = useState('')
	const [searchStringFilter, setSearchStringFilter] = useState('')
	const setFilterDeb = useRef()

	const [sortingInfo, setSortingInfo] = useState({})

	useEffect(() => {
		setSearchString(searchParams.get('q') || '')
		setParamsLoaded(true)
	}, [searchParams])

	useEffect(() => {
		return () => {
			clearTimeout(setFilterDeb && setFilterDeb.current)
		}
	}, [])

	useEffect(() => {
		if (setFilterDeb && setFilterDeb.current) clearTimeout(setFilterDeb.current)
		setFilterDeb.current = setTimeout(() => {
			setSearchStringFilter(searchString)
		}, 750)
	}, [searchString])

	// Fetch quotes on load
	useEffect(() => {
		if (paramsLoaded) {
			let completeId = ''
			let isCompleteId = false
			if (/^([^-\n ]+)-(\d+)-(\d+)$/gm.test(searchStringFilter)) {
				let matches = [...searchStringFilter.matchAll(/^([^-\n ]+)-(\d+)-(\d+)$/gm)]
				isCompleteId = true
				completeId = matches[0][3].toString()
			}

			const filter = {
				$or: [
					{ name: { $regex: searchStringFilter, $options: 'i' } },
					{ referer: { $regex: searchStringFilter, $options: 'i' } },
					{ business: { $regex: searchStringFilter, $options: 'i' } },
					{ email: { $regex: searchStringFilter, $options: 'i' } },
					{ phone: { $regex: searchStringFilter, $options: 'i' } },
					{ quoteIdentifier: { $regex: isCompleteId ? completeId : searchStringFilter, $options: 'i' } },
					{ idList: searchStringFilter },
				],
			}

			if (_hasHydrated && typeof fetchQuotes === 'function') {
				setLoading(true)
				fetchQuotes({ page: currentPage, limit: pageSize, filters: filter, sorting: sortingInfo }).then(() => {
					setLoading(false)
				})
			}
		}
	}, [_hasHydrated, fetchQuotes, currentPage, pageSize, searchStringFilter, paramsLoaded, sortingInfo])

	return (
		<div className="list-list">
			<div className="list-actions">
				<div
					className="action-button"
					onClick={() => {
						if (_hasHydrated && typeof parseQuote === 'function') {
							let selectedQuotes = quotes.filter((quote) => selectedList[quote._id])

							let parsedQuotes = selectedQuotes.map((quote) => parseQuote(quote.quote))

							const now = new Date()
							const dd = String(now.getDate()).padStart(2, '0')
							const mn = String(now.getMonth() + 1).padStart(2, '0')
							const yyyy = now.getFullYear()
							const hh = String(now.getHours()).padStart(2, '0')
							const mm = String(now.getMinutes() + 1).padStart(2, '0')

							const today = yyyy + mn + dd + '_' + hh + mm
							const filename = 'preventivi_multipli_' + today + '.json'
							let type = 'text/plain'

							var file = new Blob([JSON.stringify(parsedQuotes)], { type: type })
							if (window.navigator.msSaveOrOpenBlob)
								// IE10+
								window.navigator.msSaveOrOpenBlob(file, filename)
							else {
								// Others
								var a = document.createElement('a'),
									url = URL.createObjectURL(file)
								a.href = url
								a.download = filename
								document.body.appendChild(a)
								a.click()
								setTimeout(function () {
									document.body.removeChild(a)
									window.URL.revokeObjectURL(url)
								}, 0)
							}
						}
					}}
				>
					Scarica Multipli
				</div>
				<div
					className="action-button"
					onClick={() => {
						const filter = {
							$or: [
								{ name: { $regex: searchStringFilter, $options: 'i' } },
								{ referer: { $regex: searchStringFilter, $options: 'i' } },
								{ quoteIdentifier: { $regex: searchStringFilter, $options: 'i' } },
							],
						}

						if (_hasHydrated && typeof fetchQuotes === 'function') {
							setLoading(true)
							fetchQuotes({ page: currentPage, limit: pageSize, filters: filter }).then(() => {
								setLoading(false)
							})
						}
					}}
				>
					Sincronizza
				</div>
				<div
					className="action-button"
					onClick={() => {
						clearStore()
						// uploadQuote()
						navigate('/')
						// fetchQuotes()
					}}
				>
					Nuovo
				</div>
			</div>
			<Filters
				data={quotes}
				searchString={searchString}
				setSearchString={(value) => {
					setSearchString(value)
					navigate(`./?q=${encodeURIComponent(value)}`, { replace: true })
					// console.log(window.location)
					// setSearchParams({ q: value }, { replace: true })
				}}
			/>
			<Pagination
				// className="pagination-bar"
				currentPage={currentPage}
				totalCount={totalQuotes || 1}
				// totalCount={(filteredData && filteredData.length) || 1}
				pageSize={pageSize}
				setPageSize={setPageSize}
				onPageChange={(page) => setCurrentPage(page)}
			/>

			<div className="list-row row-header">
				<div
					className="list-column item-small"
					onClick={() => {
						let newSorting = {}
						if (!sortingInfo.quoteIdentifier) {
							newSorting.quoteIdentifier = -1
						} else if (sortingInfo.quoteIdentifier === -1) {
							newSorting.quoteIdentifier = 1
						}
						// else {
						// 	delete newSorting.quoteIdentifier
						// }

						setSortingInfo(newSorting)
					}}
				>
					ID
					<span className="sorting-icon">
						{!sortingInfo.quoteIdentifier ? (
							<FontAwesomeIcon icon={faSort} />
						) : sortingInfo.quoteIdentifier === 1 ? (
							<FontAwesomeIcon icon={faSortUp} />
						) : (
							<FontAwesomeIcon icon={faSortDown} />
						)}
					</span>
				</div>
				<div className="list-column item-medium">Azienda / Nome</div>
				<div className="list-column item-medium">Riferimento / email</div>
				<div
					className="list-column item-medium"
					onClick={() => {
						let newSorting = {}
						if (!sortingInfo.updatedAt) {
							newSorting.updatedAt = -1
						} else if (sortingInfo.updatedAt === -1) {
							newSorting.updatedAt = 1
						}
						// else {
						// 	delete newSorting.updatedAt
						// }

						setSortingInfo(newSorting)
					}}
				>
					Data ultima modifica
					<span className="sorting-icon">
						{!sortingInfo.updatedAt ? (
							<FontAwesomeIcon icon={faSort} />
						) : sortingInfo.updatedAt === 1 ? (
							<FontAwesomeIcon icon={faSortUp} />
						) : (
							<FontAwesomeIcon icon={faSortDown} />
						)}
					</span>
				</div>
				<div
					className="list-column item-medium"
					onClick={() => {
						let newSorting = {}
						if (!sortingInfo.createdAt) {
							newSorting.createdAt = -1
						} else if (sortingInfo.createdAt === -1) {
							newSorting.createdAt = 1
						}
						// else {
						// 	delete newSorting.createdAt
						// }

						setSortingInfo(newSorting)
					}}
				>
					Data creazione
					<span className="sorting-icon">
						{!sortingInfo.createdAt ? (
							<FontAwesomeIcon icon={faSort} />
						) : sortingInfo.createdAt === 1 ? (
							<FontAwesomeIcon icon={faSortUp} />
						) : (
							<FontAwesomeIcon icon={faSortDown} />
						)}
					</span>
				</div>
				<div className="list-column item-medium">Status</div>
				<div
					className="list-column item-medium"
					onClick={() => {
						let newSorting = {}
						if (!sortingInfo.priceTotal) {
							newSorting.priceTotal = -1
						} else if (sortingInfo.priceTotal === -1) {
							newSorting.priceTotal = 1
						}
						//  else {
						// 	delete newSorting.priceTotal
						// }

						setSortingInfo(newSorting)
					}}
				>
					Totale
					<span className="sorting-icon">
						{!sortingInfo.priceTotal ? (
							<FontAwesomeIcon icon={faSort} />
						) : sortingInfo.priceTotal === 1 ? (
							<FontAwesomeIcon icon={faSortUp} />
						) : (
							<FontAwesomeIcon icon={faSortDown} />
						)}
					</span>
				</div>
				{/* <div className="list-column item-small">Apri</div> */}
				<div className="list-column item-small">Duplica</div>
				<div className="list-column item-small">
					<div
						style={{ cursor: 'pointer', marginBottom: '6px' }}
						onClick={() => {
							const r = window.confirm(
								`Sei sicuro di voler eliminare ${
									Object.keys(selectedList || {}).length === 1 ? '1 preventivo' : Object.keys(selectedList || {}).length + ' preventivi'
								}?`
							)

							if (r) {
								if (typeof deleteManyQuotes === 'function') {
									deleteManyQuotes(Object.keys(selectedList || {})).then((res) => setSelectedList({}))
								}
							}
						}}
					>
						Elimina({Object.keys(selectedList || {}).length})
					</div>
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setSelectedList({})
						}}
					>
						Deseleziona
					</div>
				</div>
			</div>
			{loading ? (
				<Loading />
			) : (
				Array.isArray(quotes) &&
				quotes.map((quote, i) => {
					let date
					// if (quote.quoteDate) {
					// 	date = new Date(quote.quoteDate)
					// } else
					if (quote.updatedAt) {
						date = new Date(quote.updatedAt)
					} else {
						date = 'Sconosciuto'
					}

					let creationDate
					if (quote.createdAt) {
						creationDate = new Date(quote.createdAt)
					} else {
						creationDate = 'Sconosciuto'
					}

					return (
						<div key={quote._id} className="list-row">
							<div className="list-column item-small">
								<div className="list-column-label">ID</div>
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										replaceDocument(quote._id)
										navigate('/')
									}}
								>
									{quote.quoteIdentifier.replace(/^0+/, '')}
								</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Azienda / Nome</div>
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										replaceDocument(quote._id)
										navigate('/')
									}}
								>
									{quote.business ? `${quote.business}${quote.name ? ' / ' : ''}` : ''}
									{quote.name}
								</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Riferimento / email</div>
								<div>
									{quote.referer ? `${quote.referer}${quote.email ? ' / ' : ''}` : ''}
									{quote.email}
								</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Data</div>
								<div>{typeof date === 'string' ? date : `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`}</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Data Creazione</div>
								<div>
									{typeof creationDate === 'string'
										? creationDate
										: `${creationDate.getDate()} / ${creationDate.getMonth() + 1} / ${creationDate.getFullYear()}`}
								</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Riferimento</div>
								<div>{quote.status}</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Totale</div>
								<div>{!isNaN(quote.priceTotal) ? round(quote.priceTotal, 2) + ' €' : '0 €'}</div>
							</div>

							{/* <div
								className="list-column item-small item-clickable"
								
							>
								Apri
							</div> */}
							<div
								className="list-column item-small item-clickable"
								onClick={() => {
									replaceDocument(quote._id, true)
									navigate('/')
									// const r = window.confirm(`Vuoi sovrascrivere il quote?`)

									// if (r) {
									// 	replaceQuote(quote.quoteIdentifier ? quote.quoteIdentifier : quote._id, quote.quoteIdentifier ? false : true)
									// }
								}}
							>
								Duplica
							</div>
							<div
								className="list-column item-small hoverable item-clickable"
								style={{ userSelect: 'none' }}
								onClick={(e) => {
									// e.preventDefault()
									if (selectedList[quote._id]) {
										delete selectedList[quote._id]
									} else {
										selectedList[quote._id] = true
									}
									setSelectedList({ ...selectedList })
								}}
							>
								<input type="checkbox" checked={selectedList[quote._id] || false} onChange={() => {}} />
							</div>
						</div>
					)
				})
			)}
		</div>
	)
}
