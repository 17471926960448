import create from 'zustand'
import { persist } from 'zustand/middleware'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'

import useUserStore from './user'
import quotesStore from './quotes'
import productsStore from './products'
import useConfigurationStore from './configurations'
// uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'

import elementsActions from './documentFuncs/elements'
import exportsActions from './documentFuncs/exports'
import axios from 'axios'
import useVersionStore from './version'

let serverUrl = process.env.REACT_APP_SERVER_URL

const refreshQuote = (set, get) => {
	const token = useUserStore.getState().token
	let hasVersion = get().hasVersion
	const data = get()

	const document = data.document

	const user = useUserStore.getState().user

	// console.log(user, document)

	// refresh quote only if id is present
	if (document && document.id) {
		if (hasVersion) {
			if (user && user.authLevel >= 750) {
				return axios
					.get(`${serverUrl}/api/versions/${data._id}`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						const quote = res && res.data

						if (quote) {
							if (!document.updatedAt) {
								const importObj = parseQuote(quote, false)
								if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
									const register = quote.register
									set({ ...quote, quote: null, document: importObj.document, register: register })
									let newPriceLists = {}
									if (importObj.usedPriceList) newPriceLists.selectedPriceList = importObj.usedPriceList
									productsStore.setState(newPriceLists)
									if (importObj.attributeNames) useConfigurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
								}
							} else {
								let dateLocal = new Date(document.updatedAt)
								let dateRemote = new Date(quote.updatedAt)

								if (
									dateLocal &&
									dateRemote &&
									typeof dateLocal.getTime === 'function' &&
									typeof dateRemote.getTime === 'function' &&
									dateLocal.getTime() < dateRemote.getTime()
								) {
									const importObj = parseQuote(quote, false)
									if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
										const register = quote.register
										set({ ...quote, quote: null, document: importObj.document, register: register })
										let newPriceLists = {}
										if (importObj.usedPriceList) newPriceLists.selectedPriceList = importObj.usedPriceList
										productsStore.setState(newPriceLists)
										if (importObj.attributeNames)
											useConfigurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
									}
								}
							}
						}
					})
			} else {
				return axios
					.get(`${serverUrl}/api/versions/user/${document.id}/${document.quoteIdentifier ? 'identifier' : 'id'}/${document.versionId}/id`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						const quote = res && res.data

						if (quote) {
							if (!document.updatedAt) {
								const importObj = parseQuote(quote, false)
								if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
									const register = quote.register
									set({ ...quote, quote: null, document: importObj.document, register: register })
									let newPriceLists = {}
									if (importObj.usedPriceList) newPriceLists.selectedPriceList = importObj.usedPriceList
									productsStore.setState(newPriceLists)
									if (importObj.attributeNames) useConfigurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
								}
							} else {
								let dateLocal = new Date(document.updatedAt)
								let dateRemote = new Date(quote.updatedAt)

								if (
									dateLocal &&
									dateRemote &&
									typeof dateLocal.getTime === 'function' &&
									typeof dateRemote.getTime === 'function' &&
									dateLocal.getTime() < dateRemote.getTime()
								) {
									const importObj = parseQuote(quote, false)
									if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
										const register = quote.register
										set({ ...quote, quote: null, document: importObj.document, register: register })
										let newPriceLists = {}
										if (importObj.usedPriceList) newPriceLists.selectedPriceList = importObj.usedPriceList
										productsStore.setState(newPriceLists)
										if (importObj.attributeNames)
											useConfigurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
									}
								}
							}
						}
					})
			}
		} else {
			if (user && user.authLevel >= 750) {
				return axios
					.get(`${serverUrl}/api/quotes/${data._id}`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						const quote = res && res.data

						if (quote) {
							if (!document.updatedAt) {
								const importObj = parseQuote(quote, false)
								if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
									const register = quote.register
									set({ ...quote, document: importObj.document, quote: null, register: register })
									let newPriceLists = {}
									if (importObj.usedPriceList) newPriceLists.selectedPriceList = importObj.usedPriceList
									productsStore.setState(newPriceLists)
									if (importObj.attributeNames) useConfigurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
								}
							} else {
								let dateLocal = new Date(document.updatedAt)
								let dateRemote = new Date(quote.updatedAt)

								if (
									dateLocal &&
									dateRemote &&
									typeof dateLocal.getTime === 'function' &&
									typeof dateRemote.getTime === 'function' &&
									dateLocal.getTime() < dateRemote.getTime()
								) {
									const importObj = parseQuote(quote, false)
									if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
										const register = quote.register
										set({ ...quote, document: importObj.document, quote: null, register: register })
										let newPriceLists = {}
										if (importObj.usedPriceList) newPriceLists.selectedPriceList = importObj.usedPriceList
										productsStore.setState(newPriceLists)
										if (importObj.attributeNames)
											useConfigurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
									}
								}
							}
						}
					})
			} else {
				return axios
					.get(`${serverUrl}/api/quotes/user/${document.quoteIdentifier ? 'identifier/' : ''}${document.id}`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						const quote = res && res.data

						if (quote) {
							if (!document.updatedAt) {
								const importObj = parseQuote(quote, false)
								if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
									const register = quote.register
									set({ ...quote, document: importObj.document, quote: null, register: register })
									let newPriceLists = {}
									if (importObj.usedPriceList) newPriceLists.selectedPriceList = importObj.usedPriceList
									productsStore.setState(newPriceLists)
									if (importObj.attributeNames) useConfigurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
								}
							} else {
								let dateLocal = new Date(document.updatedAt)
								let dateRemote = new Date(quote.updatedAt)

								if (
									dateLocal &&
									dateRemote &&
									typeof dateLocal.getTime === 'function' &&
									typeof dateRemote.getTime === 'function' &&
									dateLocal.getTime() < dateRemote.getTime()
								) {
									const importObj = parseQuote(quote, false)
									if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
										const register = quote.register
										set({ ...quote, document: importObj.document, quote: null, register: register })
										let newPriceLists = {}
										if (importObj.usedPriceList) newPriceLists.selectedPriceList = importObj.usedPriceList
										productsStore.setState(newPriceLists)
										if (importObj.attributeNames)
											useConfigurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
									}
								}
							}
						}
					})
			}
		}
	}
}

function parseQuote(obj, copy) {
	try {
		const importDoc = JSON.parse(obj.quote)
		const document = importDoc.document
		if (!copy) {
			// document.id = obj.quoteIdentifier || obj._id
			// document.quoteIdentifier = obj.quoteIdentifier ? true : false
			document.id = obj._id
			document.quoteIdentifier = false
			document.identifier = obj.identifier
			document.userGroupId = obj.userGroup_id
			document.updatedAt = obj.updatedAt
		} else {
			document.id = null
			document.quoteIdentifier = null
			document.identifier = null
			document.userGroupId = null
			document.updatedAt = null
		}
		importDoc.document = document
		return importDoc
	} catch (e) {
		console.error(e)
	}
}

//TODO: Sync local document with db one
const initialDocumentStore = {
	document: {
		referer: '',
		email: '',
		phone: '',
		business: null,
		id: null,
		quoteIdentifier: null,
		identifier: null,
		name: null,
		elements: null,
		userGroupId: null,
		initialUserGroupId: null,
		updatedAt: null,
		// status: 'pending',
		// quoteDate: null,
	},
	quote: null,
	_id: null,
	id: null,
	idList: [],
	quoteIdentifier: null,
	identifier: null,
	backupData: null,
	hasVersion: false,
	versionNumber: null,
	note: null,
	register: null,
	priceTotal: 0.0,
}

/*
	add:
	versions array
	choose version
	save "default" (in case you want to work on the base quote and not in a version)
*/

// Store for user Document, possible TODO: load document info from server??
const useDocumentStore = create(
	persist(
		(set, get) => ({
			...cloneDeep(initialDocumentStore),
			clearStore: () => {
				let store = get()
				const document = cloneDeep(initialDocumentStore)
				const user = useUserStore.getState().user

				document.document.elements = [
					{
						type: 'title',
						title: '',
						id: uuidv4(),
					},
					{
						type: 'zone',
						title: '',
						id: uuidv4(),
						items: [{ id: uuidv4(), quantity: 1, name: '', info: '', price: 0.0, length: 1, discount: '' }],
					},
				]

				let functions = {}

				Object.keys(store).forEach((key, i) => {
					if (typeof store[key] === 'function') {
						functions[key] = store[key]
					}
				})

				let priceLists = productsStore.getState().priceListsNames

				let toSelectPriceList = priceLists[0]
				let index = -1
				if (Array.isArray(priceLists)) {
					index = priceLists.findIndex((pl) => pl.slug === 'aeoss')
				}
				if (index !== -1) {
					toSelectPriceList = priceLists[index]
				}

				productsStore.setState({ selectedPriceList: toSelectPriceList })

				// let selectedPriceList = productsStore.getState().selectedPriceList
				// let priceLists = productsStore.getState().priceListsNames

				// if (Array.isArray(priceLists)) {
				// 	let toSelectPriceList = priceLists[0]
				// 	let index = -1
				// 	if (Array.isArray(priceLists)) {
				// 		index = priceLists.findIndex((pl) => pl.slug === 'aeoss')
				// 	}
				// 	if (index !== -1) {
				// 		toSelectPriceList = priceLists[index]
				// 	}

				// 	let newSelectedPriceList =
				// 		selectedPriceList && priceLists.find((pl) => pl.slug === selectedPriceList.slug) ? selectedPriceList : toSelectPriceList

				// 	productsStore.setState({ selectedPriceList: newSelectedPriceList })
				// }

				if (user.changableAttribute && Array.isArray(user.attributeNames) && user.attributeNames.find((name) => name === 'aeoss')) {
					useConfigurationStore.getState().setConfiguration('attributeNames', 'aeoss')
					set({ attributeNames: 'aeoss' })
				}

				useVersionStore.getState().removeVersion()
				set({ ...document, ...functions }, true)
			},
			clearDocument: () => {
				// const document = Object.assign({}, initialDocumentStore.document)
				const document = cloneDeep(initialDocumentStore.document)
				// console.log(initialDocumentStore, document)
				set({ document: document })
			},
			setDocument: (document) => set({ document: document }),
			refreshQuote: () => refreshQuote(set, get),
			...elementsActions(set, get),
			...exportsActions(set, get),
			overrideDocument: (quote, copy = false) => {
				if (quote) {
					const user = useUserStore.getState().user
					const setConfiguration = useConfigurationStore.getState().setConfiguration

					try {
						const importObj = parseQuote(quote, copy)
						if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
							const register = quote.register
							const identifier = quote.quoteIdentifier

							set({
								...cloneDeep(initialDocumentStore),
								...quote,
								quote: null,
								document: { ...importObj.document, identifier: identifier },
								register: register,
								identifier: identifier,
							})
							if (
								user &&
								user.changableAttribute &&
								Array.isArray(user.attributeNames) &&
								user.attributeNames.includes(importObj.attributeNames) &&
								typeof setConfiguration === 'function'
							) {
								setConfiguration('attributes', importObj.attributeNames)
							}
							let newPriceLists = {}
							if (importObj.usedPriceList) newPriceLists.selectedPriceList = importObj.usedPriceList
							productsStore.setState(newPriceLists)
							if (importObj.attributeNames) useConfigurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
							useVersionStore.getState().removeVersion()
						} else {
						}
					} catch (e) {
						console.error(e)
					}
				}
			},
			replaceDocument: (id, copy = false) => {
				const store = quotesStore.getState()
				const quotes = store.quotes
				const user = useUserStore.getState().user
				const setConfiguration = useConfigurationStore.getState().setConfiguration

				if (Array.isArray(quotes)) {
					const index = quotes.findIndex((quote) => quote._id === id)
					if (index !== -1) {
						const quote = quotes[index]

						// console.log(quote)
						try {
							const importObj = parseQuote(quote, copy)
							if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
								const register = quote.register
								const updatedAt = quote.updatedAt
								const quoteDate = quote.quoteDate
								const identifier = quote.quoteIdentifier

								set({
									...cloneDeep(initialDocumentStore),
									...quote,
									document: { ...importObj.document, identifier: identifier },
									quote: null,
									register: register,
									updatedAt: updatedAt,
									quoteDate: quoteDate,
									identifier: identifier,
								})
								if (
									user &&
									user.changableAttribute &&
									Array.isArray(user.attributeNames) &&
									user.attributeNames.includes(importObj.attributeNames) &&
									typeof setConfiguration === 'function'
								) {
									setConfiguration('attributes', importObj.attributeNames)
								}

								// console.log(user, importObj)

								let newPriceLists = {}
								if (importObj.usedPriceList) newPriceLists.selectedPriceList = importObj.usedPriceList
								productsStore.setState(newPriceLists)
								if (importObj.attributeNames) useConfigurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
								useVersionStore.getState().removeVersion()
							} else {
							}
						} catch (e) {
							console.error(e)
						}
					}
				}
			},
			setRegister: (register) => {
				if (register.defaultPriceList) {
					const products = productsStore.getState()
					products.setSelectedPriceList(register.defaultPriceList)
				}

				set({ register: register })
			},
			setReferer: (value) => {
				const document = get().document
				document.referer = value
				set({ document: document })
			},
			setOrderRef: (value) => {
				const document = get().document
				document.orderRef = value
				set({ document: document })
			},
			setBusiness: (value) => {
				const document = get().document
				document.business = value
				set({ document: document })
			},
			setEmail: (value) => {
				const document = get().document
				document.email = value
				set({ document: document })
			},
			setPhone: (value) => {
				const document = get().document
				document.phone = value
				set({ document: document })
			},
			setId: (id, quoteIdentifier) => {
				const document = get().document
				document.id = id
				document.quoteIdentifier = quoteIdentifier ? true : false
				set({ document: document })
			},
			setIdentifier: (quoteIdentifier) => {
				const document = get().document
				document.identifier = quoteIdentifier
				set({ document: document })
			},
			setName: (name) => {
				const document = get().document
				document.name = name
				set({ document: document })
			},
			setNote: (note) => {
				set({ note: note })
			},
			setUserGroupId: (id) => {
				const document = get().document
				document.userGroupId = id
				set({ document: document })
			},
			setQuoteDate: (date) => {
				const document = get().document
				document.quoteDate = date
				set({ document: document, quoteDate: date })
			},
			removeVersion: () => {
				let store = get()

				if (store.backupData) {
					let newStore = {
						...store.backupData,
					}

					useVersionStore.getState().removeVersion()

					set({ ...cloneDeep(initialDocumentStore), ...newStore, backupData: null, hasVersion: false, versionNumber: null, note: null })
				}
				// backupData: store,
			},
			setVersion: (version) => {
				// console.log(version)
				if (version && version.quote) {
					let store = get()
					let removeFromStore = {}

					let backupStore = {}
					Object.keys(store).forEach((key) => {
						if (typeof store[key] === 'function') {
							// delete store[key]
						} else {
							removeFromStore[key] = null
							backupStore[key] = store[key]
						}
					})

					let register = store.register
					const id = store.id
					const quoteIdentifier = store.quoteIdentifier
					const userGroup_id = store.userGroup_id
					let updatedAt = store.updatedAt
					let quoteDate = store.quoteDate
					let versionNumber = version.versionNumber
					let versionId = version._id
					let note = version.note
					let document = store.document

					try {
						const importObj = parseQuote(version, false)
						if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
							if (version.register) register = version.register
							if (version.updatedAt) updatedAt = version.updatedAt
							if (version.quoteDate) quoteDate = version.quoteDate

							// set({ document: importObj.document, register: register, updatedAt: updatedAt, quoteDate: quoteDate })
							document = importObj.document

							// console.log(importObj)
							let newPriceLists = {}
							if (importObj.usedPriceList) newPriceLists.selectedPriceList = importObj.usedPriceList
							productsStore.setState(newPriceLists)
							if (importObj.attributeNames) useConfigurationStore.getState().setConfiguration('attributeNames', importObj.attributeNames)
						} else {
						}
					} catch (e) {
						console.error(e)
					}

					let newStore = {
						...store,
						quote: null,
						id: id,
						quoteIdentifier: quoteIdentifier,
						document: document,
						register: register,
						userGroup_id: userGroup_id,
						updatedAt: updatedAt,
						quoteDate: quoteDate,
						versionNumber: versionNumber,
						note: note,
						versionId: versionId,
					}

					set({ ...newStore, backupData: { ...backupStore, backupData: null }, hasVersion: true, versionNumber: versionNumber })
				}
			},
			setStatus: (value) => {
				// const document = get().document
				// document.status = value
				// set({ document: document })
				set({ status: value })
			},
			setWorkingStatus: (value) => {
				// const document = get().document
				// document.workStatus = value
				// set({ document: document })
				set({ workStatus: value })
			},
			setPaymentStatus: (value) => {
				// const document = get().document
				// document.paymentStatus = value
				// set({ document: document })
				set({ paymentStatus: value })
			},
			setDuePayment: (value) => {
				// const document = get().document
				// document.duePayment = value
				// set({ document: document })
				set({ duePayment: value })
			},
			setPriceTotal: (value) => {
				set({ priceTotal: value })
			},
			setAttributeNames: (value) => {
				//
				set({ attributeNames: value })
			},
			setIdList: (value) => {
				set({ idList: value })
			},
		}),
		{
			name: 'document-storage', // unique name
			// storage: typeof window !== 'undefined' ? window.localStorage : dummyStorageApi,
			// ...
			// partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key, value]) => typeof value !== 'function')),
		}
	)
)

// const functions =

export default useDocumentStore
