import axios from 'axios'

import useUserStore from './stores/user'
import useDocumentStore from './stores/document'

let serverUrl = process.env.REACT_APP_SERVER_URL

const getApps = () => {
	const token = useUserStore.getState().token
	return axios
		.get(`${serverUrl}/api/apps`, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			if (res.data) return res.data
			return null
		})
		.catch((error) => {
			console.error(error)
			return null
		})
}

const getQuotes = ({ page = 0, limit = 50, filters = {}, sorting = {} }) => {
	const token = useUserStore.getState().token
	return (
		axios
			// .get(`${serverUrl}/api/quotes`, {
			.post(
				`${serverUrl}/api/quotes/filter`,
				{ page: page, limit: limit, filters: filters, sorting: sorting },
				{
					headers: {
						authorization: token,
					},
				}
			)
			.then((res) => {
				if (res.data) return res.data
				return null
			})
			.catch((error) => {
				console.error(error)
				return null
			})
	)
}

const deleteQuote = (id) => {
	const token = useUserStore.getState().token
	return axios
		.delete(`${serverUrl}/api/quotes/${id}`, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			if (res.data) return res.data
			return null
		})
		.catch((error) => {
			console.error(error)
			return null
		})
}

const getUserGroups = () => {
	const token = useUserStore.getState().token
	return axios
		.get(`${serverUrl}/api/userGroups`, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			if (res.data) return res.data
			return null
		})
		.catch((error) => {
			console.error(error)
			return null
		})
}

const upsertQuote = (id, name) => {
	const token = useUserStore.getState().token
	// const user = useUserStore.getState().user
	const store = useDocumentStore.getState()
	const referer = store.document.referer
	const quoteDate = store.document.quoteDate
	const documnetName = store.document.name
	const business = store.document.business
	const email = store.document.email
	const phone = store.document.phone
	const register = store.register
	const idList = store.idList
	const updateData = { referer: referer, idList: idList }

	if (register) {
		updateData.register = register
	}

	if (store.document.userGroupId && id) {
		updateData.userGroup_id = store.document.userGroupId
	}

	if (!name) {
		name = documnetName
	}
	if (!name && !business) {
		name = window.prompt('Inserisci Nome per il preventivo:', '')
	}
	if (name) {
		updateData.name = name
		store.setName(name)
	}

	if (email !== undefined) {
		updateData.email = email
	}
	if (phone !== undefined) {
		updateData.phone = phone
	}
	if (business !== undefined) {
		updateData.business = business
	}

	if (store.status) {
		updateData.status = store.status
	}
	if (store.workStatus) {
		updateData.workStatus = store.workStatus
	}
	if (store.paymentStatus) {
		updateData.paymentStatus = store.paymentStatus
	}
	if (store.duePayment) {
		updateData.duePayment = store.duePayment
	}

	const exportedDcoument = store.exportDocument()
	if (name) exportedDcoument.name = name
	const quote = JSON.stringify(exportedDcoument)
	updateData.quote = quote

	if (quoteDate !== undefined) {
		updateData.quoteDate = quoteDate
	}

	updateData.priceTotal = store.priceTotal

	updateData.quoteIdentifier = undefined

	return axios
		.post(`${serverUrl}/api/quotes/upsert/${id ? id : ''}`, updateData, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			// if (res.data && (res.data.quoteIdentifier || res.data._id)) {
			// 	store.setId(res.data.quoteIdentifier || res.data._id, res.data.quoteIdentifier ? true : false)
			// }
			if (res.data && res.data._id) {
				store.setId(res.data._id, false)
				if (res.data.quoteIdentifier) store.setIdentifier(res.data.quoteIdentifier)
			}
			if (res.data && res.data.name && res.data.name !== null && res.data.name !== undefined) {
				store.setName(res.data.name)
			}
			if (res.data && res.data.referer !== null && res.data.referer !== undefined) {
				store.setReferer(res.data.referer)
			}

			// console.log(res.data)
			store.overrideDocument(res.data)
			return true
		})
}

const addUserGroup = (name) => {
	const token = useUserStore.getState().token
	return axios
		.post(
			`${serverUrl}/api/userGroups`,
			{ code: name },
			{
				headers: {
					authorization: token,
				},
			}
		)
		.then((res) => {
			if (res.data) return res.data
			return null
		})
		.catch((error) => {
			console.error(error)
			return null
		})
}

const getAttributeNames = () => {
	const token = useUserStore.getState().token
	return axios
		.get(`${serverUrl}/api/configurations/ATTRIBUTE_NAMES`, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			if (res.data) return res.data && res.data.names
			return null
		})
		.catch((error) => {
			console.error(error)
			return null
		})
}

const getPriceLists = () => {
	return axios
		.get(`${process.env.REACT_APP_PRODUCT_MANAGAER_URL}/api/pricelists`, {
			// headers: {
			// 	authorization: token,
			// },
		})
		.then((res) => {
			if (res.data) return res.data
			return null
		})
		.catch((error) => {
			console.error(error)
			return null
		})
}

const registerUser = (user) => {
	const token = useUserStore.getState().token
	return axios
		.post(`${serverUrl}/api/customers`, user, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			// console.log(res)
		})
		.catch((error) => {
			console.error(error)
		})
}

const registerWebsiteUser = (user) => {
	const token = useUserStore.getState().token
	return axios
		.post(`${serverUrl}/api/customers/website`, user, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			// console.log(res)
			return res
		})
}

const getUsers = () => {
	const token = useUserStore.getState().token
	return axios
		.get(`${serverUrl}/api/customers`, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			if (res.data) return res.data
			return null
		})
		.catch((error) => {
			console.error(error)
			return null
		})
}

const editUser = (user) => {
	const token = useUserStore.getState().token
	return axios
		.put(`${serverUrl}/api/customers/${user._id}`, user, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			if (res.data) return res.data
			return null
		})
		.catch((error) => {
			console.error(error)
			return null
		})
}

const editWebsiteUser = (user) => {
	const token = useUserStore.getState().token
	return axios
		.put(`${serverUrl}/api/customers/website/${user._id}`, user, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			if (res.data) return res.data
			return null
		})
		.catch((error) => {
			console.error(error)
			return null
		})
}

const deleteUser = (id) => {
	const token = useUserStore.getState().token
	return axios
		.delete(`${serverUrl}/api/customers/${id}`, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			if (res.data) return res.data
			return null
		})
		.catch((error) => {
			console.error(error)
			return null
		})
}

export {
	getApps,
	getUserGroups,
	getAttributeNames,
	getPriceLists,
	getUsers,
	editUser,
	editWebsiteUser,
	deleteUser,
	registerUser,
	registerWebsiteUser,
	addUserGroup,
	upsertQuote,
	getQuotes,
	deleteQuote,
}
